/* StrategyPage.css */

.strategy-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.free-tier-message {
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.free-tier-message p {
  font-size: 16px;
  color: #333;
}

.free-tier-message a {
  color: #007bff;
  text-decoration: none;
}

.free-tier-message a:hover {
  text-decoration: underline;
}

.recommendation-btn,
.add-strategy-btn,
.equi-weighted-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.strategy-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mode-toggle {
  display: flex;
  flex-direction: column;
}

.recommendation-btn {
  margin-left: auto;
  /* Ensures alignment */
}

.recommendation-btn:hover,
.add-strategy-btn:hover,
.equi-weighted-btn:hover {
  background-color: #0056b3;
}

.strategies-section {
  background-color: #ffffff;
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.strategies-header {
  text-align: center;
  margin-bottom: 20px;
}

.strategies-header h1 {
  font-size: 24px;
  color: #333;
}

.strategy-card {
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.strategy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.strategy-header h2 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.remove-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-btn:hover {
  background-color: #c82333;
}

.strategy-body {
  margin-top: 20px;

}

.allocation,
.mode-toggle {
  margin-bottom: 10px;
}




.strategy-user-inputs {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}


.mode-toggle {
  display: flex;
  flex-direction: column;
}

.mode-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  /* Add space between the header and current mode text */
}

.mode-header label {
  margin-right: 8px;
  /* Add space between the label and button */
}

.current-mode {
  margin-top: 8px;
  font-size: 14px;
  /* Optional: Adjust font size */
  color: #555;
  /* Optional: Adjust text color */
}

.toggle-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 2rem;
}

.toggle-btn:disabled,
.toggle-btn.disabled {
  background-color: #e0e0e0; /* Light grey to hint at the disabled state */
  color: #999999; /* Softer text color for contrast */
  cursor: pointer; /* Use pointer cursor to encourage clicks */
  opacity: 0.9; /* Slightly transparent but not completely faded */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: 1px solid #cccccc; /* Border to maintain a button-like appearance */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effects */
}

/* Optional: Hover effect to enhance the clickable feel */
.toggle-btn:disabled:hover,
.toggle-btn.disabled:hover {
  background-color: #d6d6d6; /* Slightly darker shade on hover */
  color: #888888; /* Darker text for hover state */
}


.recommendation-btn:disabled,
.recommendation-btn.disabled {
  background-color: #cccccc;
  /* Grey color for disabled state */
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
  /* Slightly faded to indicate disabled state */
}

.backtested-results-btn:disabled,
.backtested-results-btn.disabled {
  background-color: #cccccc;
  /* Grey color for disabled state */
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
  /* Slightly faded to indicate disabled state */
}

.toggle-btn:hover:not(.disabled) {
  background-color: #0056b3;
}

.creds-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.creds-form h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.creds-form label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

/* Dimmed Background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures it appears above other content */
}

/* Prompt Box */
.upgrade-prompt {
  background-color: #fff; /* White background for contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
  z-index: 1100; /* Higher than overlay */
}

/* Message Text */
.upgrade-prompt p {
  margin: 0 0 16px;
  font-size: 16px;
  color: #333; /* Dark text for readability */
}

/* Close Button */
.close-upgrade-message {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.close-upgrade-message:hover {
  background: #0056b3; /* Darker blue for hover effect */
}


/* Backtested Results Overlay */
.backtested-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.backtested-results-overlay .backtested-results-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.backtested-results-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttons-table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
}

.close-btn:hover {
  background-color: #c82333;
}

/* Styling for ETF Recommendations Table */


.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}



.etf-header {
  background-color: #e9ecef;
  font-weight: bold;
}

.etf-details-link-a {
  color: #007bff;
  text-decoration: none;
}

.etf-details-link-a:hover {
  text-decoration: underline;
}

/* Styling for instructions overlay */
.instructions-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.instructions-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.instructions-content h3 {
  margin-top: 0;
}

.align-right {
  float: right;
  /* Aligns the button to the right */
  margin-left: auto;
  /* Ensures spacing from other elements */
}

.show-instructions-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.show-instructions-btn:hover {
  background-color: #0056b3;
}


/* RecommendationsPage.css */

/* General Container */
.recommendations-container {
  font-family: 'Arial', sans-serif;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background: linear-gradient(to bottom, #ffffff, #f7f7f7);
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Page Title */
.page-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}

/* Recommendations Section */
.recommendations-section {
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

/* Table Header */
.table-header h2 {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Recommendations Table */
.recommendations-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.recommendations-table th,
.recommendations-table td {
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

.recommendations-table th {
  background-color: #f4f4f4;
  color: #555;
  font-weight: bold;
}

.recommendations-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.recommendations-table tr:hover {
  background-color: #eaf6ff;
}

/* Buttons for Actions */
.action-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-button {
  background-color: #4CAF50;
  /* Aesthetic green */
}

.buy-button:hover {
  background-color: #388E3C;
  /* Slightly darker green for hover */
}

.sell-button {
  background-color: #f44336;
}

.sell-button:hover {
  background-color: #d32f2f;
}

/* No Recommendations Placeholder */
.no-recommendations {
  text-align: center;
  font-style: italic;
  color: #999;
  margin: 20px 0;
  font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 768px) {

  .recommendations-table th,
  .recommendations-table td {
    font-size: 0.9rem;
  }

  .action-button {
    font-size: 12px;
    padding: 6px 10px;
  }
}



.loading-container {
  text-align: center;
  margin: 20px 0;
}

.loading-icon {
  font-size: 3rem;
  color: #007bff;
  animation: spin 1s linear infinite;
}

@media(max-width:1000px) {
  .strategy-user-inputs {
    flex-direction: column;
    gap: 1rem;
  }

  .strategy-buttons {
    flex-direction: column;
    /* Stack elements vertically */
    align-items: stretch;
    /* Make buttons span full width */
    gap: 16px;
    /* Add space between stacked elements */
  }

  .mode-toggle,
  .recommendation-btn {
    width: 100%;
    /* Make each section take up full width */
    text-align: center;
    /* Optional: Center-align text and buttons */
  }
}

/* Keyframes for spinning animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}