.section4-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    margin: 50px 20px;
    padding: 20px;
}

.section4-top-div h1 {
    font-weight: bold;
    color: #201E43;
}

.section4-top-div p {
    color: #134B70;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 30px;
}

.input-main-div {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.input-sub-div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input-box {
    padding: 13px 40px;
    border-radius: 18px;
    border: 1px solid black;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
}

.section4-bottom-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.section4-buttom-right-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
    border: 1px solid black;
    border-radius: 10px;
    min-width: 200px;
}

.radio-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.radio-label {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
}

.custom-radio {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.section4-button-div {
    margin-top: 10px;
    min-width: 300px;
}

@media (max-width: 768px) {
    .section4-main-div {
        margin: 30px 10px;
    }

    .section4-top-div p {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .input-main-div {
        flex-direction: column;
        gap: 20px;
    }

    .section4-bottom-div {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .section4-buttom-right-div {
        width: 100%;
        padding: 15px;
    }

    .section4-button-div {
        min-width: 250px;
    }
}

@media (max-width: 480px) {
    .input-box {
        width: 100%;
        padding: 10px;
    }

    .section4-main-div {
        margin: 20px;
    }

    .section4-button-div {
        margin-top: 0px;
        min-width: 300px;
    }
}
