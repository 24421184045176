/* Contact Us Container Styling */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f7f7f7;
    height: 100vh;
    justify-content: center;
    text-align: center;
}

/* Title Styling */
.contact-title {
    font-size: 2.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
}

/* Info Section Styling */
.contact-info {
    max-width: 500px;
    width: 100%;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

/* Hover Effect for Info Section */
.contact-info:hover {
    transform: translateY(-5px);
}

/* Contact Name Styling */
.contact-name {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #0a4d8a;
}

/* Contact Description Styling */
.contact-description {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #777;
}

/* Contact Link Styling */
.contact-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #0a4d8a;
    font-size: 1.2em;
    font-weight: bold;
    margin: 15px 0;
    padding: 12px 25px;
    border-radius: 50px;
    background-color: #e3f2fd;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Hover Effect for Contact Links */
.contact-link:hover {
    background-color: #0a4d8a;
    color: #fff;
    transform: translateY(-3px);
}

/* Active State for Contact Links */
.contact-link:active {
    background-color: #01579b;
}

/* Contact Icon Styling */
.contact-icon {
    margin-right: 12px;
    font-size: 1.5em;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .contact-title {
        font-size: 2em;
    }

    .contact-info {
        width: 90%;
        padding: 25px;
    }

    .contact-link {
        font-size: 1em;
        padding: 10px 20px;
    }

    .contact-icon {
        font-size: 1.3em;
    }
}
