.blog-list-page {
    max-width: 1200px;
    margin: 20px auto;
    padding: 10px;
}

.blog-list-header {
  text-align: center;
  margin-bottom: 20px;
}

.blog-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #fff;
  padding: 1px;
}

.blog-card {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.blog-card-content {
  padding: 10px;
  text-align: center;
}

@media (max-width: 1024px) {
  .blog-list-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .blog-list-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .blog-list-container {
    grid-template-columns: 1fr;
  }

  .blog-list-header h1 {
    font-size: 1.2rem;
  }
}
