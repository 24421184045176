.register-page-container {
  display: flex;
  height: 90vh;
}

.register-left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.register-container {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.register-form-container {
  width: 100%;
}

.form-group {
  position: relative;
}

.form-label-register {
  display: flex;
  justify-content: start;
}

.form-input {
  position: relative;
  width: 100%;
}

.form-input input {
  width: 100%;
  padding-right: 40px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.form-input input:focus {
  border-color: #007bff;
  outline: none;
}

.password-toggle-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #555;
}

.password-toggle-icon:hover {
  color: #007bff;
}

.message-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}

.message-item {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

.message-item a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.message-item a:hover {
  text-decoration: underline;
}

.register-button {
  width: 100%;
  padding: 12px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.register-button:hover {
  background: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 86, 179, 0.3);
}

.register-button:active {
  background: #004085;
  box-shadow: 0 3px 6px rgba(0, 64, 133, 0.2);
  transform: translateY(2px);
}

.register-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
}

.register-right img {
  width: 100%; 
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .register-page-container {
    flex-direction: column;
  }

  .register-left{
    width: 100%;
    height: 50vh;
  }

  .register-right {
    display: none;
  }

  .register-right img {
    object-fit: cover;
  }

  .register-header-h2 {
    margin-top: 100px;
  }
}

@media (max-width: 500px) {
  .register-left {
    padding: 10px;
  }

  .form-input input {
    font-size: 0.9rem;
    padding: 8px;
  }

  .register-button {
    font-size: 0.8rem;
    padding: 8px;
  }
}

/* ---------------------------------------  */
/* CSS */
.button-84 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 44px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-84:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.button-84:active {
  outline: 0;
}

.button-84:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .button-84 {
    height: 48px;
  }
}