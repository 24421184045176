.etf-details {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .etf-details h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #2c3e50;
  }
  
  .etf-details p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #34495e;
  }
  
  .order-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .order-section label {
    font-weight: bold;
    color: #2c3e50;
  }
  
  .order-section select,
  .order-section input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .order-section button {
    padding: 10px;
    /* background-color: #3498db; */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
.confirmation-dialog {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 300px;
  }
  
  .confirmation-dialog h2 {
    margin-bottom: 1rem;
    color: #2c3e50;
  }
  
  .confirmation-dialog p {
    margin-bottom: 0.5rem;
  }
  
  .confirm-btn, .cancel-btn {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 0.5rem;
  }
  
  .cancel-btn {
    background-color: #6c757d;
  }
  
  .confirm-btn:hover, .cancel-btn:hover {
    opacity: 0.9;
  }

  .buy-btn {
    background-color: #3498db;
    color: white;
  }
  
  .sell-btn {
    background-color: rgb(243, 82, 82);
    color: white;
  }
  
  .disabled-button {
    background-color: gray;
    color: white;
  }
  
  .confirm-btn {
    background-color: green;
    color: white;
  }
  
  .cancel-btn {
    background-color: red;
    color: white;
  }
  
  .creds-form {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
  }
  
  .creds-form h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
  }
  
  .creds-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  .creds-form input {
    width: calc(100% - 30px);
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .creds-form a {
    font-size: 0.875rem;
    color: #007bff;
    text-decoration: none;
    margin-left: 5px;
  }
  
  .creds-form a:hover {
    text-decoration: underline;
  }
  
  .save-creds-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .save-creds-btn:hover {
    background-color: #0056b3;
  }
  