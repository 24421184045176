body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}

/* Container */
.feedback-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.feedback-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 2rem;
}

/* cards */
/* ******* make it grid 1 at mobile  */
.feedback-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

/* Card styling */
.feedback-card {
    position: relative;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feedback-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feedback-type {
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
}

.feedback-card p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
}

.feedback-date {
    font-size: 0.8rem;
    color: #888;
    margin-top: 10px;
    font-style: italic;
}

.feedback-delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #dc3545;
    font-size: 20px;
}

.feedback-delete-icon:hover {
    cursor: pointer;
}
