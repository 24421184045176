.orders-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.orders-page h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.orderpage-loading {
  animation: spin 1s linear infinite;
}

.orderpage-loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gains {
  text-align: center;
  font-size: 1.2em;
  margin: 20px 0;
}

.gains span {
  font-weight: bold;
  color: #4CAF50;
}

.chart-container {
  margin: 30px 0;
  max-width: 100%;
  overflow-x: auto;
  /* Allow horizontal scrolling if needed */
}




.orders-list {
  margin-top: 20px;
}

.orders-list h3 {
  color: #4CAF50;
  margin-bottom: 10px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.orders-table th,
.orders-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.orders-table th {
  background-color: #4CAF50;
  color: white;
}

.orders-table tr:hover {
  background-color: #f1f1f1;
}

.orders-table td {
  color: #555;
}

.orders-table tr:nth-child(even) {
  background-color: #f9f9f9;
}


.date-filter {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.date-filter h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #333;
}

.date-filter label {
  margin-right: 15px;
  font-size: 1em;
  color: #555;
}

.date-filter input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  margin-right: 10px;
}

.date-filter button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.date-filter button:hover {
  background-color: #4CAF50;
  /* Change color on hover */
  color: white;
}

.date-filter button:active {
  background-color: #388E3C;
  /* Darker shade on active */
}

.date-filter button.reset {
  background-color: #f44336;
  /* Red for reset button */
  color: white;
}

.date-filter button.reset:hover {
  background-color: #d32f2f;
  /* Darker red on hover */
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.error-highlight input {
  border: 1px solid red;
}


.canvas-container
{
  position: relative;
  margin: auto;
  height: 50vh;
  width: 100%;
}

.predefined-ranges button.selected {
  background-color: #388E3C;
  color: white;
}

/* Adjust date filter input and buttons */
@media (max-width: 1000px) {

  
  .date-filter label {
    display: block;
    margin-bottom: 8px;
  }

  .date-filter input[type="date"] {
    width: 100%;
    /* Full-width inputs for mobile */
    margin-bottom: 10px;
  }

  .date-filter button {
    width: 100%;
    margin-bottom: 10px;
  }

  .orders-page {
    padding: 10px;
  }

  .orders-table th,
  .orders-table td {
    font-size: 0.8em;
    padding: 6px;
  }

  .chart-container {
    padding: 0 10px;
    /* Add padding for better mobile fit */
  }
}