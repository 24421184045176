.section3-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 0;
}

.newlandingpage-side-gap {
  margin-left: 217px;
  margin-right: 217px;
}

.section3-top-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.section3-top-div h1 {
  font-size: 40px;
  font-weight: bold;
  color: #201e43;
}

.section3-top-div p {
  font-size: 18px;
  color: #444;
  max-width: 600px;
}

.section3-bottom-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
}

.section3-boxs-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  padding: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(148, 218, 148, 0.238);
  border: 1px solid #ccc;
  min-height: 250px;
  min-width: 280px;
  transition: transform 0.3s ease-in-out;
}

.section3-boxs-div:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 20px rgba(148, 218, 148, 0.3);
}

.section3-boxs-div img {
  max-width: 100px;
  height: auto;
  margin-bottom: 15px;
}

.section3-boxs-div h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}

.section3-boxs-div p {
  text-align: center;
  font-size: 16px;
  margin: 0;
  color: #134b70;
}

@media (max-width: 1370px) {
  .section3-bottom-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 1024px) {
  .section3-bottom-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  .section3-top-div h1 {
    font-size: 35px;
  }

  .newlandingpage-side-gap {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 768px) {
  .section3-bottom-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: -30px;
  }

  .section3-top-div {
    margin-top: 20px;
  }
  .section3-top-div h1 {
    font-size: 30px;
  }

  .section3-top-div p {
    font-size: 16px;
  }

  .section3-boxs-div {
    padding: 15px;
  }

  .section3-boxs-div img {
    max-width: 70px;
  }

  .newlandingpage-side-gap {
    margin-left: 50px;
    margin-right: 50px;
  }

  .section3-main-div {
    margin-bottom: -20px;
  }
}

@media (max-width: 490px) {
  .section3-main-div {
    margin-bottom: -20px;
    padding: 10px;
  }

  .section3-top-div {
    margin-top: 20px;
    padding: 0 10px;
  }

  .section3-top-div h1 {
    font-size: 24px;
    width: 100%;
    padding: 0 10px;
  }

  .section3-top-div p {
    font-size: 14px;
    width: 100%;
    padding: 0 10px;
  }

  .section3-bottom-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
    width: 100%;
  }

  .section3-boxs-div {
    min-height: auto;
    min-width: auto;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .section3-boxs-div img {
    max-width: 120px;
    margin-bottom: 10px;
  }

  .section3-boxs-div h2 {
    font-size: 16px;
    margin: 8px 0;
  }

  .section3-boxs-div p {
    font-size: 12px;
    margin: 0;
  }
}
