body {
  font-family: 'Roboto', sans-serif; /* Replace with desired Google Font */
  background-color: var(--background-color);
  color: var(--text-color);
}


code {
  --font-family: 'Roboto', sans-serif;
  font-size: 1rem;


}
/* global.css */

:root {
  --primary-color: #5a8dee;
  --secondary-color: #5f97ef;
  --color-border-5--rgb:#000;
  --background-color: #f3f4f4;
  --text-color: #333;
  --color-bg-default: #fff;
  --text-font-size: 0.9rem;
  --color-border-default:#eee
}
/* You can define other global styles here */

/* Example usage */
.navbar {
  background-color: var(--primary-color);
}

.sidebar {
  background-color: var(--secondary-color);
}


/* Custom scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 6px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
  border: 3px solid #f1f1f1; /* Adds padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #E4EDEF; /* Color of the thumb on hover */
}