.backtested-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 9999;
}

.backtested-results-container {
  background: #1e1e1e;
  padding: 25px;
  border-radius: 12px;
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 1300px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);
  position: relative;
}

.backtested-results-left {
  flex: 0 0 25%;
  padding: 20px;
  border-right: 1px solid #555;
  overflow-y: auto;
  font-size: 0.95rem;
  text-align: left;
}

.backtested-results-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-left: 20px;
}

.controls-section {
  flex: 0 0 15%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.investment-type-selector {
  margin-bottom: 2px;
}

.investment-type-selector label {
  margin-right: 15px;
  font-size: 1.1rem;
  cursor: pointer;
  color: white ;
}

.investment-type-selector input[type="radio"] {
  margin-right: 8px;
  transform: scale(1.2);
  cursor: pointer;
}

.result-buttons {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 8px;
}

.result-buttons li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.result-buttons button {
  background: linear-gradient(135deg, #28a745, #218838);
  color: white;
  padding: 6px 8px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 0.9rem;
}

.result-buttons button.selected {
  background: linear-gradient(135deg, #218838, #1e7e34);
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.result-buttons button:hover {
  background: linear-gradient(135deg, #218838, #1e7e34);
  transform: scale(1.1);
  /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
}

.result-buttons button:active {
  transform: scale(1);
}

.chart-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.chart-section img {
  max-width: 95%;
  max-height: 95%;
  margin-bottom: 25px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
}

.chart-section p {
  text-align: center;
  font-size: 1.1rem;
}

.close-btn {
  background: linear-gradient(135deg, #dc3545, #c82333);
  padding: 8px;
  border: none;
  border-radius: 20%;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.close-btn:hover {
  background: linear-gradient(135deg, #dc3545, #c82333);
  transform: scale(1.1);
}

.close-btn:active {
  transform: scale(0.9);
}
/* Responsive Design */
/* Responsive Design for screens with max-width 1000px */
@media (max-width: 1000px) {
  .backtested-results-container {
    flex-direction: column;
    padding: 10px;
    height: auto;
  }

  .backtested-results-left {
    flex: 0 0 auto;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #555;
  }

  .backtested-results-right {
    flex: 1;
    margin-left: 0;
    margin-top: 10px;
    padding: 2px;
  }

  .controls-section {
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  .result-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .result-buttons li {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .chart-section {
    flex: 1;
    padding: 0;
  }

  .chart-section img {
    max-width: 95%;
    max-height: 95%;
  }

  .close-btn {
    padding: 10px 20px;
    top: 10px;
    right: 10px;
  }
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow: hidden;
  padding: 10px;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.controls {
  position: absolute;
  top: 100px;
  right: 10px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.controls button {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.403);
  border: none;
  color: white;
  margin: 5px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.controls button:hover {
  background-color: rgba(255, 255, 255, 0.737);
}

.close-modal-btn {
  position: absolute;
  top: 18px;
  right: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-modal-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}

@media (max-width: 1482px) {
  .modal-content {
    padding: 10px;
  }

  .modal-image {
    width: auto;
    height: auto;
  }

  .controls {
    top: 70px;
    right: 10px;
    flex-direction: row;
    gap: 1px;
  }

  .controls button {
    margin: 5px;
    padding: 4px 15px;
  }

  .close-modal-btn {
    right: 20px;
  }
}

.backtested-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
