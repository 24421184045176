.graph-title {
    font-size: large;
    text-align: center;
    margin-bottom: 20px;
}

.graph-heading-span {
    color: #666;
    font-size: 16px;
    margin-left: 10px;
}

.graph-container {
    width: 100%;
}

/* Tooltip styling */
.custom-tooltip {
    background-color: white; /* Set background to white */
    padding: 10px 15px;
    border-radius: 5px;
    color: black; /* Text color black */
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.custom-tooltip .label {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.custom-tooltip .date-label {
    margin-top: 5px;
    font-size: 12px;
    color: #718EBF;
}

.custom-tooltip p {
    margin-bottom: 5px;
}
