.testimonial-container {
  padding: 1.5rem 0;
  text-align: center;
}

.testimonial-content {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  overflow: hidden;
}

.testimonial-text {
  font-style: italic;
  margin: 0 1rem 1rem;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.testimonial-author {
  color: #6c757d;
  font-weight: bold;
  margin-top: 0.5rem;
}

.mdb-carousel .carousel-indicators {
  bottom: -1.5rem;
}

.mdb-carousel .carousel-control-prev,
.mdb-carousel .carousel-control-next {
  filter: invert(50%);
}

.testimonial-controls {
  display: flex;
  gap: 50px;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.control-icon:hover {
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonial-content {
    padding: 2px;
  }

  .testimonial-text {
    font-size: 0.9rem;
    white-space: normal;
  }

  .testimonial-author {
    font-size: 0.85rem;
  }
}

@media (max-width: 576px) {
  .testimonial-container {
    padding: 1rem 0;
  }

  .testimonial-content {
    border-radius: 0px;
    height: 200px;
  }

  .testimonial-text {
    margin: 0 0.5rem 1rem;
  }
}
