.portfolio-container {
    position: relative;
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    color: #333;
    font-family: Arial, sans-serif;
    background-color: #fdfdfd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #004085;
    margin-bottom: 20px;
    font-size: 1.8em;
  }
  
  .sort-icon-div {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .sort-icon {
    font-size: px;
  }
  
  .portfolio-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    font-size: 0.9rem;
  }
  
  .portfolio-table th, 
  .portfolio-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
  }
  
  .portfolio-table th {
    background-color: #007bff;
    color: #fff;
    font-weight: 600;
    position: sticky;
    top: 0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .portfolio-table th:hover {
    background-color: #0056b3;
  }
  
  .portfolio-table tbody tr {
    transition: background-color 0.3s;
  }
  
  .portfolio-table tbody tr:nth-child(odd) {
    background-color: #f8f9fa;
  }
  
  .portfolio-table tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  .portfolio-table tbody tr:hover {
    background-color: #e9ecef;
  }
  
  .portfolio-table td {
    color: #333;
    font-weight: 500;
  }
  
  .portfolio-table td.gain {
    color: #28a745;
    font-weight: bold;
  }
  
  .portfolio-table td.loss {
    color: #dc3545;
    font-weight: bold;
  }
  
  .buy-sell-button {
    padding: 6px 12px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buy-sell-button:hover {
    background-color: #0056b3;
  }
  
  .sort-icon {
    font-size: 0.8rem;
    margin-left: 5px;
    transition: transform 0.3s ease;
  }
  
  .sort-asc .sort-icon {
    transform: rotate(180deg);
  }
  
  /* ------ sorting -------  */
  .portfolio-container {
    position: relative;
    font-family: Arial, sans-serif;
  }
  
  .sort-icon-div {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .sort-icon {
    font-size: 24px;
    color: #333;
    transition: transform 0.2s ease;
  }
  
  .sort-icon-div:hover .sort-icon {
    transform: scale(1.2);
  }
  
  .sort-drawer {
    position: fixed;
    top: 120px;
    right: 50px;
    width: 200px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
    padding: 15px;
    transition: all 0.3s ease-in-out;
  }
  
  .sort-drawer h3 {
    text-align: center;
    font-weight: bold;
    margin: 0 0 10px;
    font-size: 16px;
    color: #333;
  }
  
  .sort-drawer ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .sort-drawer .sorting-option {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: normal;
    color: #333;
    transition: background-color 0.2s ease;
  }
  
  .sorting-option:hover {
    background-color: #f9f9f9;
  }
  
  .sorting-option.active {
    background-color: #e0f7fa;
    font-weight: bold;
  }
  
  .sort-direction {
    font-size: 12px;
    color: #888;
  }
  
  .sort-drawer ul li:last-child {
    border-bottom: none;
  }
  
  /* ----------- responsive ---------  */
  
  /* Responsive Adjustments */
  @media (max-width: 1000px) {
    .portfolio-table {
      font-size: 0.85rem;
    }
  
    .portfolio-table th,
    .portfolio-table td {
      padding: 8px 10px;
    }
  
    .portfolio-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 1.5em;
    }
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .overlay-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    text-align: center;
  }
  
  /* ------------ dev-tejas -----------  */
  /* -------------------------  */
  .clickable-row {
    cursor: pointer;
  }
  
  .desktop-view {
    display: block;
  }
  
  .mobile-view {
    display: none;
  }
  
  .mobile-view .left-section {
    font-weight: normal;
  }
  
  .mobile-view .left-section {
    font-weight: normal;
  }
  
  @media (min-width: 769px) {
    .sort-icon-div {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .desktop-view {
      display: none;
    }
  
    .mobile-view {
      display: block;
    }
  
    /* Responsive Card Layout */
  
    .portfolio-card-div {
      padding: 0px 10px;
      background-color: #f8f9fa;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
    }
  
    .portfolio-card-div-hr {
      margin-top: -1px;
      margin-bottom: 7px;
    }
  
    .portfolio-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
  
    .bottom-portfolio-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      padding: 0px 10px;
    }

    .bottom-portfolio-card-div {
      display: flex;
      gap: 5px;
    }
  
    .bottom-portfolio-card-amount{
      font-size: 14px;
    }
  
    .portfolio-card .left-section {
      flex: 1;
    }
  
    .mobile-view .portfolio-card .left-section .symbol {
      font-size: 1rem;
      font-weight: normal;
      color: #004085;
      margin-bottom: 1px;
    }
  
    .mobile-view .details {
      font-size: 0.9rem;
      font-weight: normal;
      color: #6c757d;
      margin-bottom: 1px;
    }
  
    .portfolio-card .right-section {
      text-align: right;
    }
  
    .mobile-view .portfolio-card .right-section .gain {
      font-size: 1rem;
      font-weight: normal;
      color: #28a745; /* Green for gain from above CSS */
      margin-bottom: 1px;
    }
  
    .mobile-view .portfolio-card .right-section .loss {
      font-size: 1rem;
      font-weight: normal;
      color: #dc3545; /* Red for loss  from above CSS*/
      margin-bottom: 1px;
    }
  
    /* this for (gain%)  */
    .mobile-view .portfolio-card .right-section .gain-per {
      font-size: 0.9rem;
      font-weight: normal;
      color: #28a745; /* Green for gain from above CSS */
      margin-bottom: 1px;
    }
  
     /* this for (loss%)  */
    .mobile-view .portfolio-card .right-section .loss-per {
      font-size: 0.9rem;
      font-weight: normal;
      color: #dc3545; /* Red for loss  from above CSS*/
      margin-bottom: 1px;
    }
  
    .portfolio-card .right-section .gain-percentage {
      font-size: 0.9rem;
      color: #6c757d;
    }
  }
  