.profile-page {
  position: relative;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-bottom: 20px;
}

.creds-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.creds-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.creds-form input,
.creds-form select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.invalid-dropdown {
  border-color: red;
  background-color: #ffe6e6;
}

.save-creds-btn,
.edit-creds-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.save-creds-btn:hover,
.edit-creds-btn:hover {
  background-color: #0056b3;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 30px; /* Adjust this value as needed */
}

.password-input span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.downloadables {
  display: flex;
  flex-direction: column;
  padding: 2px;
  padding-bottom: 20px;
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download img {
  width: 50px;
}

.download a {
  text-decoration: none;
  color: #007bff;
}

.close {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.close:hover {
  background: #0056b3;
}

.feedback-btn-div {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

@media (max-width: 480px) {
  .feedback-btn-div {
    right: 10px;
    bottom: 10px;
  }
}

.feedback-btn {
  display: flex;
  align-items: center;
  background-color: #00c3b9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #009e94;
}

.feedback-inside-btn-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #00c3b9;
  width: 30px;
  height: 30px;
}

.feedback-a-tag {
  text-decoration: none;
}

.edit-creds-btns {
  display: flex;
  gap: 10px;
}

.save-creds-btn,
.edit-creds-btn-logout {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.save-creds-btn:hover,
.edit-creds-btn-logout:hover {
  background-color: #0056b3;
}

.edit-creds-btn-logout-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.referral-link-div {
  display: flex;
  justify-content: start;
  align-items: center;
}

.referral-link-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-decoration: none;
  background: linear-gradient(135deg, #00C3B9, #67cac5);
  color: white;
  padding: 8px 16px;
  border-radius: 15px;
  font-weight: 500;
  font-size: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.referral-link-section:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.referral-link-section div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.referral-link-section svg {
  font-size: 12px;
}

.referral-link-section span {
  font-size: 12px;
}

@media (min-width: 1000px) {
  .edit-creds-btn-logout {
    display: none;
  }
}