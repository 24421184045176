.body1-main-div {
  margin-bottom: 20px;
}

.body1-heading-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.body1-heading-div h1 {
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  color: #201e43;
}

.body1-heading-div p {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
}

.body1-spans {
  font-weight: 700;
}

.body1-spans-footer {
  font-weight: 700;
  font-style: italic;
}

.body1-content-left-div img {
  width: 400px;
}

.body1-content-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.body1-content-right-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 20px;
  padding: 10px;
}

.body1-boxs-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 29px;
  margin-bottom: 20px;
}

.body1-box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 4px 15px rgba(148, 218, 148, 0.238);
  transition: transform 0.3s ease-in-out;
}

.body1-box1:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 20px rgba(148, 218, 148, 0.3);
}

.body1-footer {
  margin-top: 20px;
}

.body1-footer h2 {
  text-align: center;
  font-size: 28px;
  font-weight: 900;
}

/* Tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .body1-heading-div h1 {
    font-size: 35px;
  }
  .body1-heading-div p {
    font-size: 14px;
  }
  .body1-content-div {
    flex-direction: column;
    text-align: center;
  }
  .body1-content-left-div img {
    width: 350px;
  }
  .body1-boxs-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .body1-footer h2 {
    font-size: 24px;
  }
  .body1-content-left-div {
    display: none;
  }
}

/* Mobile devices (max-width: 768px) */
@media (max-width: 768px) {
  .body1-heading-div h1 {
    font-size: 28px;
  }
  .body1-heading-div p {
    font-size: 13px;
  }
  .body1-content-left-div {
    display: none;
  }
  .body1-boxs-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  .body1-box1 {
    width: 90%;
    padding: 15px;
  }
  .body1-footer h2 {
    font-size: 20px;
  }
  .body1-button-div {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .body1-main-div {
    padding-top: 40px;
  }
  .body1-heading-div {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .body1-heading-div h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .body1-heading-div p {
    font-size: 14px;
    line-height: 1.5;
    color: #555;
  }

  .body1-content-left-div {
    display: flex;
    justify-content: center;
    margin-bottom: -30px;
  }
  .body1-content-left-div img {
    width: 250px;
  }

  .body1-boxs-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 20px;
  }
  .body1-box1 {
    width: 100%;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(148, 218, 148, 0.238);
  }
  .body1-box1 img {
    width: 50px;
  }
  .body1-box1 p {
    font-size: 12px;
  }

  .body1-boxs-div .body1-box1:last-child {
    grid-column: span 2;
    justify-self: center;
    width: 50%;
  }

  .body1-footer {
    margin-top: 20px;
    padding: 0 20px;
  }
  .body1-footer p {
    font-size: 14px;
    text-align: center;
  }
}
