.common-background {
  background: url("./assets/top-image01.png") no-repeat center center;
  background-size: cover;
  width: 100%;
}

.newlandingpage-bg-gap {
  background-color: #eeeeee;
  margin-bottom: -30px;
}

.newlandingpage-section4-div {
  background: url("./assets/section4bg.png") no-repeat center center;
  background-size: cover;
  width: 100%;
}

@media (min-width: 1000px) {
  .newlandingpage-main-div .newlandingpage-side-gap {
    margin-left: 217px;
    margin-right: 217px;
  }
}
