.section5-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.section5-main-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.section5-left-div {
  flex: 1;
  text-align: center;
  min-width: 250px;
}

.section5-left-div img {
  max-width: 80%;
  height: auto;
}

.section5-left-div h1 {
  font-size: 24px;
  font-weight: bold;
  color: #201E43;
  margin-top: 10px;
}

.section5-left-div p {
  font-size: 14px;
  color: #134B70;
  font-weight: bold;
  margin-top: 5px;
}

.section5-right-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-width: 250px;
  box-sizing: border-box;
}

.section5-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  transition: border 0.3s;
  box-sizing: border-box;
}

.section5-button {
  background-color: #EEEEEE;
  color: black;
  padding: 10px 18px;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  border: 1px solid black;
}

.section5-button-div {
  margin-top: 10px;
  margin-bottom: -20px;
}

@media (max-width: 850px) {
  .section5-main {
    margin-top: -10px;
    width: 90%;
    padding: 25px 10px;
  }

  .section5-main-div {
    flex-direction: column;
    align-items: center;
  }

  .section5-left-div img {
    max-width: 50%;
  }

  .section5-button-div {
    margin-top: 10px;
    margin-bottom: -20px;
  }
}
