.section9-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 30px auto;
    max-width: 1200px;
    width: 90%;
    padding-bottom: 50px;
}

.section9-faq-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.section9-faq-heading h1 {
    font-size: 28px;
    color: #112D33;
    margin-bottom: 10px;
}

.section9-faq-heading p {
    font-size: 16px;
    font-weight: bold;
    color: #535B62;
}

.section9-span {
    color: #1F7AE0;
    cursor: pointer;
}

.section9-span:hover {
    text-decoration: underline;
    color: #1e77dd;
}

.section9-categories {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.section9-category {
    flex: 1;
}

.section9-category h2 {
    font-size: 20px;
    color: #112D33;
    margin-bottom: 15px;
}

.section9-question-item {
    margin-bottom: 10px;
}

.section9-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.section9-question:hover {
    border-radius: 5px;
    background-color: #e0e0e0;
}

.section9-question h3 {
    font-size: 14px;
    color: #333;
    margin: 0;
}

.section9-arrow {
    font-size: 12px;
    transition: transform 0.3s ease;
}

.section9-arrow.open {
    transform: rotate(180deg);
}

.section9-answer {
    padding: 10px;
    margin-top: 5px;
}

.section9-answer p {
    font-size: 12px;
    color: #555;
    margin: 0;
}

.section9-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background-color: #1F7AE0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.section9-more-button:hover {
    background-color: #1e77dd;
}

@media (max-width: 768px) {
    .section9-categories {
        flex-direction: column;
        width: 90vw;
    }

    .section9-category {
        width: 100%;
    }

    .section9-faq-heading h1 {
        font-size: 25px;
    }

    .section9-question h3 {
        font-size: 16px;
    }

    .section9-answer p {
        font-size: 14px;
    }

    .section9-more-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .section9-faq-heading h1 {
        font-size: 25px;
    }

    .section9-question h3 {
        font-size: 14px;
    }

    .section9-answer p {
        font-size: 12px;
    }

    .section9-more-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}