.section10-container {
  background-color: #FFFFFF;
  padding: 40px 10px 0px;
  margin-top: 10px;
}

.section10-top {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.section10-left {
  flex: 1;
  max-width: 400px;
}

.section10-logo {
  width: 150px;
  margin-bottom: 20px;
}

.section10-find-us {
  font-size: 18px;
  font-weight: bold;
  color: #112d33;
  margin-bottom: 10px;
}

.section10-social-icons {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.section10-social-icons svg {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.section10-social-icons svg:hover {
  transform: scale(1.1);
}

.section10-contact {
  font-size: 14px;
  color: #535b62;
  margin-bottom: 10px;
}

.section10-contact a {
  color: #1f7ae0;
  text-decoration: none;
}

.section10-contact a:hover {
  text-decoration: underline;
}

.section10-insights {
  font-size: 14px;
  color: #535b62;
  margin-bottom: 20px;
}

.section10-email-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.section10-email-icon {
  position: absolute;
  left: 10px;
  color: #888;
}

.section10-email-input input {
  width: 100%;
  padding: 10px 10px 10px 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

.section10-email-input button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #1f7ae0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.section10-email-input button:hover {
  background-color: #1e77dd;
}

.section10-right {
  flex: 1;
  max-width: 400px;
}

.section10-links {
  display: flex;
  gap: 40px;
}

.section10-link-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.section10-link-heading {
  font-size: 18px;
  font-weight: bold;
  color: #112d33;
  margin-bottom: 10px;
}

.section10-link-column a {
  font-size: 14px;
  color: #535b62;
  text-decoration: none;
  transition: color 0.3s ease;
}

.section10-link-column a:hover {
  color: #1f7ae0;
}

.section10-disclaimer {
  max-width: 1200px;
  margin: 20px auto 0;
  padding: 10px;
}

.section10-disclaimer p {
  font-size: 12px;
  color: #555;
  text-align: center;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .section10-top {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .section10-left,
  .section10-right {
    max-width: 100%;
    text-align: center;
  }

  .section10-social-icons {
    justify-content: center;
  }

  .section10-email-input {
    justify-content: center;
  }

  .section10-links {
    flex-direction: row;
    gap: 50px;
  }

  .section10-link-column {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .section10-container {
    padding: 20px 10px;
  }

  .section10-logo {
    width: 120px;
  }

  .section10-find-us {
    font-size: 16px;
  }

  .section10-social-icons svg {
    size: 20px;
  }

  .section10-contact,
  .section10-insights {
    font-size: 12px;
  }

  .section10-email-input input {
    font-size: 12px;
  }

  .section10-email-input button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .section10-link-heading {
    font-size: 16px;
  }

  .section10-link-column a {
    font-size: 12px;
  }

  .section10-disclaimer p {
    font-size: 10px;
  }
}
