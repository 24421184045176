.custom-carousel-container {
  position: relative;
  width: 90%;
  padding: 0 20px;
}

.custom-carousel-content {
  overflow: hidden;
  scroll-behavior: smooth;
}

.custom-carousel-slides {
  display: flex;
  gap: 5px;
  padding: 10px;
}

.custom-carousel-slide {
  flex: 1;
  padding: 10px;
  min-width: 0;
}

@media (min-width: 768px) {
  .custom-carousel-slide {
    flex: 0 0 calc((100% - 20px) / 3);
  }
}

@media (max-width: 767px) {
  .custom-carousel-slide {
    flex: 0 0 110%;
  }

  .custom-carousel-container {
    width: 100%;
  }

  .custom-carousel-slides {
    gap: 0px;
  }

  .custom-prev {
    left: 0;
  }

  .custom-carousel-slides {
    padding: 0px;
    margin-right: 25px;
  }
}

.custom-blog-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.custom-blog-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-blog-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.custom-blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.custom-blog-content {
  padding: 8px 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-blog-date {
  color: #666;
  font-size: 14px;
  margin: 0 0 4px;
}

.custom-blog-title {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  margin: 4px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-blog-description {
  color: #666;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-blog-author {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
}

.custom-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.custom-nav-button:hover {
  background: #f5f5f5;
}

.custom-prev {
  left: 0;
}

.custom-next {
  right: 0;
}