body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

.admin-user-info {
  overflow: hidden;
  height: 90vh;
}

.admin-user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  text-align: center;
}

.admin-user-info-title {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.checkbox-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.custom-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
  position: relative;
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label:hover .custom-checkbox {
  border-color: #0056b3;
}

.admin-user-info-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 400px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .admin-user-info-chart {
    width: 100%;
    height: 600px;
  }
}
