.body1-button {
    width: 250px;
    height: 70px;
    background-color: #9ACBD0;
    border-radius: 10px;
    border: none;
    color: black;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.body1-button:hover {
    background-color: #201E43;
    color: white;
}

@media (max-width: 768px) {
    .body1-button {
        width: 200px;
        height: 60px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .body1-button {
        width: 150px;
        height: 55px;
        font-size: 14px;
    }
}
