.admin-login-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 8px;
  }
  
  .admin-login-container {
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .admin-login-form-container {
    width: 100%;
  }
  
  .form-group {
    position: relative;
  }
  
  .form-input {
    position: relative;
    width: 100%;
  }
  
  .form-input input {
    width: 100%;
    padding-right: 40px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .form-input input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #555;
  }
  
  .password-toggle-icon:hover {
    color: #007bff;
  }
  
  .message-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  
  .message-item {
    margin: 0;
    font-size: 0.9rem;
    color: #555;
  }
  
  .message-item a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .message-item a:hover {
    text-decoration: underline;
  }
  
  .login-button {
    width: 100%;
    padding: 12px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .login-button:hover {
    background: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 86, 179, 0.3);
  }
  
  .login-button:active {
    background: #004085;
    box-shadow: 0 3px 6px rgba(0, 64, 133, 0.2);
    transform: translateY(2px);
  }
  
  @media (max-width: 1000px) {
    .login-page-container {
      flex-direction: column;
    }

  }
  
  @media (max-width: 500px) {
    .login-left {
      padding: 10px;
    }
  
    .form-input input {
      font-size: 0.9rem;
      padding: 8px;
    }
  
    .login-button {
      font-size: 0.8rem;
      padding: 8px;
    }
  }
  