.container {
    /* max-width: 800px; */
  }
  
  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .table-responsive {
    margin-top: 20px;
  }
  
  .table thead {
    background-color: #343a40;
    color: white;
  }
  
  .table td, .table th {
    text-align: center;
    vertical-align: middle;
  }
  .payments-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .payments-modal  {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    height: 80%;
    overflow-y: auto;
    position: relative;
  }
  


  .runcode-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .runcode-modal  {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    height: 80%;
    overflow-y: auto;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .logout-btn-admin {
    display: flex;
    justify-content: end;
    align-items: end;
  }