.top-div {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(5px);
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 35px;
  position: relative;
  padding-top: 10px;
}

.left-container {
  text-decoration: none;
}

.left-container img:hover{
  cursor: pointer;
}

.logo-image {
  width: 150px;
  height: auto;
}

.menu-icon {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 0 20px;
  background: #eeeeee;
  padding: 10px 30px;
  gap: 0 50px;
  border: 1px solid black;
}

.center-container a {
  text-decoration: none;
  font-weight: bold;
  color: black;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  font-size: 14px;
}

.center-container a:hover {
  transform: translateY(-2px);
}

.right-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar-button {
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  transition: all 0.3s ease;
}

.navbar-login {
  background-color: #eeeeee;
  color: black;
  border: 1px solid black;
}

.navbar-signup {
  background-color: #201E43;
  color: white;
  border: 1px solid #201E43;
}

.navbar-signup:hover {
  opacity: 0.8;
  color: #eeeeee;
}

.mobile-login-button {
  display: none;
}

@media (max-width: 892px) {

  .menu-icon {
    display: block !important;
  }
  
  .menu-icon {
    display: block;
    font-size: 28px;
    cursor: pointer;
    z-index: 100;
  }

  .center-container,
  .right-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 66px;
    left: 0;
    right: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
    text-align: left;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: transform 0.4s ease-out, max-height 0.4s ease-out, opacity 0.3s ease-in, visibility 0.3s ease-in;
  }

  .center-container.active,
  .right-container.active {
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    padding: 10px 0;
  }

  .center-container {
    border: none;
  }

  .center-container a {
    text-decoration: none;
    font-weight: bold;
    color: black;
    padding: 12px 20px;
    display: block;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .center-container a:last-child {
    border-bottom: none;
  }

  .center-container a:hover {
    background: #f5f5f5;
  }

  .right-container {
    top: auto;
    bottom: 0;
    width: 100%;
    background: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
  }

  .navbar-button {
    width: 90%;
    display: block;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
  }

  .navbar-login {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  .navbar-signup {
    background-color: #201E43;
    color: white;
    border: 1px solid #201E43;
  }

  .navbar-signup:hover {
    opacity: 0.8;
    color: #eeeeee;
  }

  .mobile-login-button {
    display: block;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    background-color: #eeeeee;
    color: black;
    border: 1px solid black;
    max-width: 100px;
    margin-left: auto;
  }  

  .menu-icon {
    display: none;
  }
}

@media (max-width: 480px) {
  .logo-image {
    width: 130px;
  }

  .menu-icon {
    font-size: 24px;
  }
}