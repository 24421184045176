/* Overlay Container */
.etf-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Order Wrapper */
.order-wrapper {
  background-color: #2c2c2c;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #fff;
  position: relative;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
}

/* Order Heading */
.order-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.order-heading-left {
  display: flex;
  flex-direction: row;
}

.order-symbol-name {
  font-size: 1rem;
  font-weight: bold;
  margin-right :0.5rem;
}

.order-price {
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.6rem;
}

.order-price.positive {
  color: #00c853;
}

.order-price.negative {
  color: #f44336;
}

.order-price-change {
  font-size: 1rem;
}

.order-body {
  margin-top: 10px;
}

/* Order Inputs and Buttons */
.order-section-wrapper label {
  font-size: 1rem;
  margin-top: 10px;
  color: white;
}

.order-section-wrapper select,
.order-section-wrapper input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #888;
  background-color: #333;
  color: white;
}

.order-section-wrapper button {
  width: 100%;
  padding: 12px;
  /* background-color: #00c853; */
  border: none;
  color: white;
  font-size: 1rem;
  margin-top: 20px;
  border-radius: 6px;
  cursor: pointer;
}

.order-section-wrapper button:hover {
  background-color: #00796b;
}

.confirmation-dialog {
  background-color: #333;
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;
  color: #333;
}

.confirm-btn {
  background-color: #4caf50;
}

.close-button-end {
  background-color: #f44336;
}


/* Buy Button */
.buy-button {
  background-color: #00c853; /* Green */
}

.buy-button:hover {
  background-color: #00796b;
}

/* Sell Button */
.sell-button {
  background-color: #f44336; /* Red */
}

.sell-button:hover {
  background-color: #d32f2f;
}

/* Close Button */
.close-button-end {
  background-color: #157d85; /* Red for consistency */
  color: black;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.close-button-end:hover {
  background-color: #d32f2f;
}
