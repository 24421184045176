/* ProductPage.css */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
  color: #333;
}

.toggle-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  /* Center items horizontally */
  align-items: center;
  /* Center items vertically */
  font-size: 1em;
}

.toggle-switch {
  margin: 0 10px;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0a4d8a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.products-container {
  gap: 20px;
  max-width: 1300px;
  width: 90%;
  /* padding: 20px; */
  margin: auto;
  justify-content: center;
}

.products-header {
  text-align: center;
}

.products-header h1 {
  color: #0a4d8a;
  margin: 20px 0 10px;
}

.price-card-desc {
  text-align: center;
  height: 10%;
}

.card-heading {
  height: 8%;
}
.features {
  text-align: left;
  margin: 25px 0;
  height: 50%;
  /* height: 30vh; */
}

.features h4 {
  font-size: 1.1em;
  color: #333;
  margin: 10px 0;
  font-weight: bold;
}

.benefits-section,
.strategy-section,
.pricing-section {
  background-color: #fff;
  border-radius: 12px;
  /* padding: 30px; */
  margin-bottom: 30px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.benefits-section h2,
.strategy-section h2,
.pricing-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #007bff;
}

.benefits-section p,
.strategy-section p {
  line-height: 1.6;
  font-size: 1.1em;
}

.benefits-section ul {
  list-style: disc;
  margin-left: 20px;
  line-height: 1.6;
}

blockquote {
  margin: 20px 0;
  font-style: italic;
  color: #555;
  border-left: 4px solid #007bff;
  padding-left: 15px;
}

button.pricing-link {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s;
  /* position: absolute;
   bottom: 0;  */
}

button.pricing-link:hover {
  background-color: #0056b3;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-heading {
  margin-bottom: 30px;
}

.pricing-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping of cards */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1em;
  /* flex-direction: column; */
  width: 100%;
}

.pricing-card {
  background-color: #e3f2fd;
  border-radius: 12px;
  padding: 30px;
  margin: 10px;

  flex-grow: 1;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  /* text-align: center; */
  transition: transform 0.3s;
  flex-direction: column;
}

.pricing-card:hover {
  transform: translateY(-10px);
}

.pricing-card h3 {
  color: #0a4d8a;
  /* margin: 10px 0 10px; */
  text-align: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container label {
  font-size: 1em;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  font-size: 1em;
  text-align: left;
}

.pricing-card ul li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.pricing-card ul li.checked::before {
  content: "✔";
  color: green;
  margin-right: 10px;
}

.pricing-card ul li.unchecked::before {
  content: "✖";
  color: red;
  margin-right: 10px;
}

button.purchase-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0a4d8a;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  width: 100%;
  margin-top: auto; /* Push the button to the bottom */
}
.price {
  font-size: 1.2em;
  color: #0a8a0a;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
}

button.purchase-button:hover {
  background-color: #218838;
}

.payment-form {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.payment-form h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #007bff;
}

.payment-form .qr-code {
  max-width: 150px;
  margin-bottom: 20px;
}

.payment-form input {
  display: block;
  width: calc(100% - 40px);
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.payment-form button.submit-payment {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.payment-form button.submit-payment:hover {
  background-color: #0056b3;
}

@media (max-width: 1000px) {
  .pricing-card-container {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    max-width: 100%;
  }
}

/* Other CSS styles */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.payment-form h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #007bff;
}

.payment-form .qr-code {
  max-width: 150px;
  margin-bottom: 20px;
}

.payment-form input {
  display: block;
  width: calc(100% - 40px);
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.payment-form button.submit-payment {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.payment-form button.submit-payment:hover {
  background-color: #0056b3;
}

.original-price {
  /* text-decoration: line-through; */
  color: #0a8a0a;
  font-weight: bold;
  font-size: 1.2em;
}

.discounted-price {
  color: #0a8a0a;
  font-weight: bold;
  font-size: 1.2em;
  margin-left: 10px;
}

.banner {
  max-width: 90%;
  padding: 15px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: pulsate 3s infinite;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
}

@keyframes pulsate {
  0% {
    background-color: #ff7f50;
  }

  /* Coral */
  50% {
    background-color: #ffdf00;
  }

  /* Yellow */
  100% {
    background-color: #ff7f50;
  }

  /* Coral */
}

/* ------------ coupon  */

.coupon-section {
  margin: 20px 0;
  padding: 5px;
  /* background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.coupon-section form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.coupon-section input[type="text"] {
  padding: 10px;
  width: 20%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
}

.coupon-section input[type="text"]:focus {
  border-color: #007bff;
}

.coupon-section button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.coupon-section button:hover {
  background-color: #0056b3;
}

.coupon-section p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #28a745;
  background-color: #e9f7e9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #28a745;
}

.coupon-section p {
  font-size: 16px;
  color: #007bff;
}

@media (max-width: 1000px) {
  .coupon-section input[type="text"] {
    width: 40%;
  }

  .coupon-section button {
    padding: 12px 24px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .coupon-section input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .coupon-section button {
    padding: 12px 24px;
    font-size: 16px;
    width: 100%;
  }

  .coupon-section form {
    flex-direction: column;
    align-items: stretch;
  }

  .coupon-section button {
    margin-top: 10px;
  }

  .original-price{
    font-size: 1em;
  }
}
