
.section8-heading-div {
    color: #201E43;
    text-align: center;
}

.section8-main-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 90vh;
}

.section8-heading-div h1 {
    margin-top: 30px;
}