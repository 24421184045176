.section6-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.section6-title {
    color: #112D33;
    margin-bottom: 20px;
}

.section6-graph-container {
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(41, 46, 51, 0.3);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.top-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    flex-wrap: wrap;
}

.top-item {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 5px;
    min-width: 120px;
}

.top-item:hover {
    transform: translateY(-2px);
}

.top-item.active {
    color: #134B70;
    font-weight: bold;
}

.top-line {
    border: none;
    height: 2px;
    border-radius: 100px;
    margin: 5px 0;
    transition: background-color 0.1s ease-in, height 0.1s ease-in;
}

.top-line.active {
    background-color: black;
    height: 4px;
}

.graph-section {
    width: 100%;
    padding: 10px 40px;
    min-height: 250px;
}

@media (max-width: 768px) {
    .section6-title {
        font-size: 28px;
    }

    .top-item {
        font-size: 12px;
        padding: 8px;
        min-width: 100px;
    }

    .graph-section {
        min-height: 200px;
    }
}

@media (max-width: 480px) {
    .section6-title {
        font-size: 28px;
    }

    .top-item {
        font-size: 10px;
        padding: 5px;
        min-width: 80px;
    }

    .graph-section {
        padding: 10px 10px;
        min-height: 90px;
    }

    .top-section {
        margin-bottom: 0px;
    }
}