.feedback-page {
  max-width: 600px;
  margin: 10px auto;
  padding: 10px;
  background-color: white;
  font-family: Arial, sans-serif;
}

.cross-feedback-div {
  display: flex;
  justify-content: end;
}

.cross-feedback-icon {
  cursor: pointer;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.cross-feedback-icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.cross-feedback-icon:active {
  transform: scale(0.9);
  opacity: 0.8;
}

.feedback-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.feedback-form label {
  display: block;
  margin-bottom: 10px;
  color: #555;
  font-weight: bold;
  text-align: left; /* Ensures labels are aligned to the start on all screens */
}

.feedback-form input[type="text"],
.feedback-form select,
.feedback-form textarea,
.feedback-form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: inherit;
}

.feedback-form textarea {
  height: 100px;
  resize: vertical;
}

.feedback-form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
  transition: background-color 0.3s;
}

.feedback-form button:hover {
  background-color: #0056b3;
}

.message {
  text-align: center;
  color: green;
  font-size: 14px;
  margin-top: 10px;
}

.required-star {
  color: red;
  margin-left: 5px;
}

.submit-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.submit-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.sending {
  font-weight: bold;
}

.gray-btn {
  background-color: #d3d3d3;
  color: #888;
}

@media screen and (max-width: 768px) {
  .feedback-page {
    padding: 10px;
    margin: 5px auto; /* Reduced margin for smaller screens */
  }

  .feedback-form label {
    margin-bottom: 5px;
  }

  .feedback-form input[type="text"],
  .feedback-form select,
  .feedback-form textarea,
  .feedback-form button {
    font-size: 14px;
    padding: 8px;
    margin-bottom: 10px;
  }

  .feedback-form button {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .feedback-page {
    padding: 5px;
    margin: 5px auto; /* Small top margin for very small screens */
  }

  .feedback-form label {
    text-align: left; /* Ensures labels are aligned to the start on all screens */
    margin-bottom: 5px;
  }

  .feedback-form input[type="text"],
  .feedback-form select,
  .feedback-form textarea,
  .feedback-form button {
    font-size: 12px;
    padding: 6px;
    margin-bottom: 8px; /* Reduced gap between inputs for small screens */
  }

  .feedback-form button {
    padding: 8px;
  }
}
