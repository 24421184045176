.blog-detail-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}

.blog-detail-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  line-height: 1.3;
}

.blog-detail-container p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.blog-content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 30px;
}

.blog-content h2,
.blog-content h3,
.blog-content h4 {
  margin-top: 20px;
  color: #2c3e50;
}

.blog-content p {
  margin-bottom: 20px;
}

.blog-content ul,
.blog-content ol {
  margin-left: 30px;
}

.blog-content ul li,
.blog-content ol li {
  margin-bottom: 10px;
}

.blog-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-content a {
  color: #3498db;
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}

.blog-detail-container .blog-header {
  margin-bottom: 30px;
}

.blog-detail-container .blog-header h1 {
  font-size: 2rem;
  color: #333;
}

.blog-back-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 20px;
}

.blog-back-button:hover {
  background-color: #2980b9;
}

.blog-detail-delete-icon {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    cursor: pointer;
    font-size: large;
}

@media (max-width: 768px) {
  .blog-detail-container {
    padding: 15px;
  }

  .blog-detail-container h1 {
    font-size: 2rem;
  }

  .blog-detail-container p {
    font-size: 1rem;
  }

  .blog-content {
    font-size: 1rem;
  }

  .blog-back-button {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .blog-detail-container {
    padding: 10px;
  }

  .blog-detail-container h1 {
    font-size: 1.8rem;
  }

  .blog-content {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .blog-back-button {
    font-size: 1rem;
    padding: 12px 20px;
  }
}
