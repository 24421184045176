.post-blog-container {
  max-width: 1100px;
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.post-blog-container h1 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #333;
}

.post-blog-container input[type="text"] {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.post-blog-container input[type="file"] {
  margin-bottom: 1.5rem;
}

.post-blog-container .w-md-editor {
  margin-bottom: 1.5rem;
}

.post-blog-container button {
  display: block;
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.post-blog-container button:hover {
  background-color: #0056b3;
}

.post-blog-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .post-blog-container {
    padding: 1rem;
  }

  .post-blog-container h1 {
    font-size: 1.5rem;
  }

  .post-blog-container button {
    font-size: 0.9rem;
  }
}
