.blog-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-card-link {
  text-decoration: none;
}

.blog-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  flex: 1;
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.blog-card:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.blog-image-container {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #f4f4f4;
  color: #888;
  border-radius: 8px;
  text-align: center;
}

.blog-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 0;
}

.blog-date {
  font-size: 0.85rem;
  font-weight: bold;
  color: #555;
}

.blog-title-description {
  margin-top: -20px;
}

.blog-title-description h2 {
  text-align: left;
  font-size: 1.2rem;
  color: #333;
  margin: 0 0 8px;
}

.blog-title-description p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.blog-author {
  margin-top: 2px;
  font-size: 0.9rem;
  color: #444;
  font-weight: bold;
  margin-bottom: -50px;
}

@media (max-width: 768px) {
  .blog-card {
    max-width: 100%;
  }
}
