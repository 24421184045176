.section2 {
  position: relative;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  max-width: 800px;
  z-index: 1;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section2 {
    height: 50vh;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .section2 {
    height: 45vh;
    padding: 15px;
  }

  .content {
    padding: 10px;
  }

  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.95rem;
  }
}
