body {
    margin: 0;
    padding: 0;
}

.coupons-container {
    max-width: 1500px;
    margin: 20px auto;
    padding: 20px;
}

.coupons-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.coupons-top h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    flex-basis: 100%;
    text-align: center;
}

.coupon-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
}

.form-field label {
    display: block;
    font-weight: bold;
}

.form-field input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    width: 100%;
}

.required-red {
    color: red;
}

.coupon-p-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.coupon-span {
    font-size: large;
    font-weight: bold;
}

.coupon-code {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;
}

.button-50 {
    appearance: button;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    color: white;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-generate {
    appearance: button;
    background-color: black;
    border: 1px solid black;
    border-radius: 4px;
    color: white;
    padding: 11px 30px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-50:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.btn-generate:hover {
    background-color: rgb(89, 89, 89);
    border-color: rgb(89, 89, 89);
}

.coupon-copy {
    display: flex;
    gap: 5px;
    align-items: center;
}

.coupons-bottom {
    margin-top: 5px;
}

.coupon-icon {
    margin-left: 8px;
    color: #007bff;
}
