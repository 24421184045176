.admin-payments-container {
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.filter-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.filter-button:hover {
  background-color: #0056b3;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.payments-table {
  width: 100%;
  border-collapse: collapse;
}

.payments-table th,
.payments-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.payments-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.verify-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.reject-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.verify-button:disabled,
.reject-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.verify-button[disabled] {
  background-color: grey;
  cursor: not-allowed;
}

.reject-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.sum-of-accepted-payments {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
  font-size: large;
  font-weight: bold;
}
