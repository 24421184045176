/* LandingPage.css */

.img-box-our-commitment {
  /* size: 10px; */
  width: 100%;
}

.img-our-commitment {
  width: 60%;
  margin-left: 7rem;
}

.detail-box-our-commitment {
  margin-top: 5rem;
  /* 
  display: table-cell;
  vertical-align: middle; */
}

.wcu-img {
  width: 125px;
  margin: auto;
}

.wcu-heading {
  margin: auto;
  text-align: center;
}

.wcu-img img {
  filter: invert(100%) brightness(100%);
}

/* Global section styling */
.about_section _expense {
  background-color: #f5f7fa;
  padding: 50px 0;
  font-family: "Open Sans", sans-serif;
}

/* Heading styles */
.heading_container_expense h2 {
  font-size: 2.5rem;
  color: #00204a;
  margin-bottom: 40px;
  text-align: center;
}

/* Section title styling */
.expense-heading {
  font-size: 2rem;
  color: #004085;
  margin-bottom: 20px;
}

.expense-subheading {
  font-size: 1.5rem;
  color: #00204a;
  margin-bottom: 10px;
}

/* Section content */
.expense-paragraph {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.7;
}

/* Card styling */
.fund-type-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.fund-type-card:hover {
  transform: translateY(-5px);
}

/* Table styling */
.table-container {
  overflow-x: auto;
  margin-top: 30px;
}

.expense-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.expense-table th,
.expense-table td {
  padding: 15px;
  text-align: left;
  font-size: 1.1rem;
}

.expense-table th {
  background-color: #004085;
  color: #fff;
}

.expense-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

/* Footer styling */
.footer {
  background-color: #e9ecef;
  padding: 20px;
  margin-top: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.expense-footer-text {
  font-size: 0.9rem;
  color: #555;
}

.landing-page-blog-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.landing-page-blog-heading {
  margin-top: 20px;
}

/* Media Queries */
@media (max-width: 1000px) {
  .expense-subheading {
    font-size: 1.3rem;
  }

  .expense-heading,
  .expense-subheading {
    text-align: center;
  }

  .expense-table th,
  .expense-table td {
    font-size: 1rem;
  }

  .expense-paragraph {
    font-size: 1rem;
  }
}

.conclusion {
  color: white;
  width: 80%;
  margin: auto;

  margin-top: 5rem;
  /* margin-bottom: 1rem; */
  text-align: center;
  background-color: #004085;
}

.conclusion p {
  color: white;
}

.conclusion h2 {
  /* color: white ;
  ma */
  margin-top: 1rem;
}

.detail-box-joinus-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.detail-box-joinus {
  /* margin-top: 10rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.faq-wrapper {
  margin-top: 20px;
  text-align: center;
}

.faq-text {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.faq-link {
  color: #4caf50;
  text-decoration: none;
  font-weight: bold;
}

.faq-link:hover {
  text-decoration: underline;
  color: #388e3c; /* Slightly darker green */
}

.backtested-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 9999;
  overflow-y: auto;
  /* Enables vertical scrolling */
}

/* Optional: Adding a maximum height to limit the height and allow scrolling if content overflows */
.backtested-results-container {
  background: #1e1e1e;
  padding: 25px;
  border-radius: 12px;
  display: flex;
  width: 100%;
  height: auto;
  /* Allow height to adjust dynamically */
  max-width: 1300px;
  max-height: 90vh;
  /* Limits the container height to 90% of viewport height */
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);
  position: relative;
  overflow-y: auto;
  /* Allows scrolling within the container */
}

.backtested-results-overlay .backtested-results-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.backtested-results-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn1 {
  border-radius: 0.25rem;
  /* Smooth, rounded corners */
}

.feedback-btn-div {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

.feedback-btn {
  display: flex;
  align-items: center;
  background-color: #00c3b9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #009e94;
}

.feedback-inside-btn-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #00c3b9;
  width: 30px;
  height: 30px;
  margin-left: -10px;
}

/* ------------ blog css -------------  */
.blogs-container-landing {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
}

.blogs-cards-wrapper-landing {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: center;
  width: 100%;
}

.blog-card-landing {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  width: 30%;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.blog-card-landing:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.blog-card-image-landing {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
}

.blog-card-content-landing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.blog-card-title-landing {
  text-align: left;
  font-size: 1.4rem !important;
  font-weight: normal;
  font-weight: normal !important;
  color: #333 !important;
}

.blog-card-description-landing {
  font-size: 0.9rem !important;
  font-weight: 100 !important;
  color: #666;
  margin: 0;
}

.blog-card-date-landing {
  font-size: 0.75rem;
  font-weight: bold;
  color: #555;
}

.blog-card-creator-landing {
  font-size: 0.75rem;
  font-weight: bold;
  color: #444;
}

.blogs-button-landing {
  padding: 8px 16px;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin: 8px;
}

.blogs-button-prev {
  margin-right: -50px;
}

.blogs-button-next {
  margin-left: 50px;
}

.blogs-button-landing:hover {
  background-color: #e5e8eb;
}

@media (max-width: 768px) {
  .blogs-cards-wrapper-landing {
    flex-direction: column;
    gap: 8px;
  }

  .blog-card-landing {
    width: 100%;
  }

  .blogs-button-prev,
  .blogs-button-next {
    top: 45%;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }
}

/* -------------- graph container CSS -------------- */
.graph-container-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  justify-items: center;
}

.graph-heading-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px;
  margin-bottom: 40px;
}

.graph-container-div > * {
  flex: 1 1 45%;
  max-width: 45%;
  box-sizing: border-box;
}

.faq-sign-btn-div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .faq-sign-btn-div {
    flex-direction: row;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .graph-container-div > * {
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 10px;
    margin-left: 20px;
    gap: 2  5px;
  }

  .graph-container-div canvas {
    width: 100% !important;
    height: auto !important;
  }

}

 /* ------------------------ btns ------------------  */
/* CSS */
.button-65 {
  appearance: none;
  background-color: #00bbf0;
  border-radius: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 45px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.button-65:hover {
  background-color: #007ea4ba;
  box-shadow: rgba(0, 0, 0, 0.05) 0 5px 30px, rgba(0, 0, 0, 0.05) 0 1px 4px;
}

.button-65:active {
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 6px 0, rgba(0, 0, 0, 0.1) 0 0 10px 0, rgba(0, 0, 0, 0.1) 0 1px 4px -1px;
  transform: translateY(2px);
}

@media (min-width: 768px) {
  .button-65 {
    padding: 14px 22px;
    width: 150px;
  }
}