.dashboard {
  display: flex;
  height:  95vh;
  background-color: #ffffff;
}

.sidebar {
  background-color: #f8f9fa;
  color: #2c3e50;
  padding: 1rem;
  width: 100%;
  overflow-y: auto;
  height: 100%; 
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* margin-left: 6rem; */
  border-radius: 8px;
}

.sidebar h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #2c3e50;
}

.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
}

.clear-search-button {
  position: absolute;
  right: 5%;
  top: 10%;
  /* align-items: right; */
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.clear-search-button:hover {
  color: #333;
}

.search-bar {

  flex: 1;
  padding-right: 2rem;
  /* Space for the clear button */
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dashboard-dropdown {
  display: block;
  margin-top: -1rem;
  /* Adjust negative margin to pull it upwards */
  width: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 0.5rem;
  max-height: 100%;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-dropdown-item {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #e0e0e0;
  /* Add horizontal line */

}

.dashboard-dropdown-item:hover {
  background-color: #f1f1f1;
}

.add-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.add-button:hover {
  background-color: #218838;
}

.watchlist {
  list-style: none;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;

}

.watchlist-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ecf0f1;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.watchlist-item:hover {
  background-color: #dfe6e9;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.stock-up {
  border-left: 4px solid green;
}

.stock-down {
  border-left: 4px solid red;
}

.stock-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.stock-info-left {
  width: 60%;
  font-size: 0.95rem;
}

.stock-info-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  align-items: flex-end;
  font-size: 0.85rem;
}

.stock-symbol {
  font-weight: bold;
  flex: 1;
  transition: opacity 0.3s ease;
  color: #2c3e50;
}

.stock-ltp {
  flex: 1;
  text-align: right;
  transition: opacity 0.3s ease;
  color: #2c3e50;
}

.stock-change {
  flex: 1;
  text-align: right;
  transition: opacity 0.3s ease;
  color: #2c3e50;
}

.action-window {
  position: absolute;
  top: 100%;
  /* Default positioning below */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  gap: 1rem;
  /* Space between buttons */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.action-window.above {
  top: auto;
  bottom: 100%;
  /* Position above the parent element */
}

.action-window::before {
  content: '';
  position: absolute;
  top: -8px;
  /* Arrow position when below */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  /* Matches background color */
  z-index: 1;
}

.action-window.above::before {
  top: auto;
  bottom: -8px;
  /* Arrow position when above */
  border-bottom: none;
  border-top: 8px solid #ffffff;
  /* Arrow pointing upwards */
}

.action-window .button {
  background-color: #007bff;
  /* Button color */
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.action-window .button:hover {
  background-color: #0056b3;
}

.action-window .remove-button {
  background-color: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color: #fff;
}

.action-window .remove-button:hover {
  background-color: #c82333;
}

.stock-buttons {
  position: relative;
  right: 10px;
  /* top: 50%; */
  transform: translateY(-50%);
  /* display: none; */
  animation: fadeIn 0.3s ease;
}

.remove-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.remove-button:hover {
  background-color: #c82333;
}

/* .watchlist-item:hover .stock-info .stock-symbol, */
.watchlist-item:hover .stock-info .stock-ltp,
.watchlist-item:hover .stock-info .stock-change {
  /* opacity: 0; */
}

/* Style for the LTP when the change is positive */
.stock-ltp.positive {
  color: green;
}

/* Style for the LTP when the change is negative */
.stock-ltp.negative {
  color: red;
}


.watchlist-item:hover .stock-buttons {
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50%) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
