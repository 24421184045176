/* DummyPortfolio.css */

.portfolio-wrapper {
  max-width: 100%;
  margin: 30px auto;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  overflow-x: auto;
  font-family: Arial, sans-serif;
}

/* Form Styles */
.portfolio-form-section {

  
}

.portfolio-form-section h3 {
  margin-bottom: 15px;
  font-size: 1.25rem;
  color: #0056b3;
}.dummyportfolio-form-body {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Adjust the gap between elements as needed */
  justify-content: space-between;
  width: 100%; /* Ensure it takes the full horizontal space */
}

.dummyportfolio-form-body > div {
  flex: 1; /* Make each child take equal space */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.dummyportfolio-form-body select,
.dummyportfolio-form-body input,
.dummyportfolio-form-body button {
  width: 100%; /* Make inputs take full width of their parent */
  padding: 10px; /* Add some padding for better appearance */
  font-size: 16px; /* Adjust font size for better readability */
  border: 1px solid #ccc; /* Add border for inputs and select */
  border-radius: 5px; /* Optional: Rounded corners */
  box-sizing: border-box; /* Include padding and border in element's width */
}

.dummyportfolio-form-body button {
  background-color: #007bff; /* Add a background color to the button */
  color: white; /* Button text color */
  border: none; /* Remove default button border */
  cursor: pointer; /* Pointer cursor for button */
}

.dummyportfolio-form-body button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}



.portfolio-form-section select,
.portfolio-form-section input,
.portfolio-form-section button {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 8px 0;
  padding: 10px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.portfolio-form-section button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}




.portfolio-form-section button:hover {
  background-color: #0056b3;
}

/* Table Styles */
.portfolio-data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
}

.portfolio-data-table th,
.portfolio-data-table td {
  padding: 12px 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.portfolio-data-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.portfolio-data-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.portfolio-data-table tbody tr:hover {
  background-color: #eef3fb;
}

.portfolio-data-table td {
  color: #333;
}

.portfolio-data-table td.gain-positive {
  color: #28a745;
  font-weight: bold;
}

.portfolio-data-table td.gain-negative {
  color: #dc3545;
  font-weight: bold;
}

.delete-button {
  padding: 5px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c82333;
}
