
.section7-heading-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.section7-heading-div p{
    color: #134B70;
    font-size: 18px;
    font-weight: bold;
}

.section7-heading-div h3{
    color: #201E43;
    font-size: 18px;
    font-size: 25px;
    font-weight: bold;
}

@media (min-width: 1000px) {
    .section7-main-div {
        margin-left: 100px;
        margin-right: 100px;
    }
}