  /* 305 and 29 line number */
.navbar {
  font-size: var(--text-font-size);
  /* min-width: 320px; */
  background-color: var(--color-bg-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  z-index: 9;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-bg-default);
  /* box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1); */
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.header-left {
  width: 17%;
  /* width: 15%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbar-logo {
  width: 35px; /* Adjust the width as needed */
  border-radius: 5px;
  color: #333;
  height: auto;
  text-decoration: none;
  margin: auto;
}

.header-right {
  width: 68%;
  display: flex;
  justify-content: right;
  margin-right: 0;
  align-items: center;
  /* margin-top: 10px; */
  margin-bottom: -10px;

}

/* .logo {
  height: 50px; Adjust as needed
} */

/* .silver-shadow {
  box-shadow: 0 0px 20px silver;
  
}

.gold-shadow {
  box-shadow: 0 0px 20px gold;
} */


.market-data {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.market-item {
  font-size: 0.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.trading-symbol {
  font-weight: 600;
}

.ltp {
  margin-left: 0.25rem;
  font-weight: 400;
}

.market-item .change {
  font-size: 0.625rem;
  margin-left: 0.25rem;
}

.positive {
  color: var(--positive-change);
}

.negative {
  color: var(--negative-change);
}

.title {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-left: 1rem;
  font-weight: 700;
}
.navLinks {
  list-style: none;
  display: flex;
  align-items: center; /* Vertically center the nav links */
  font-size: 1rem;
  /* margin-right: 10rem; */
}

.navLinks li {
  margin: 0 1rem;
}

.navLink {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #ff5722;
}

.navLink.active-link a {
  color: #ff5722;
  font-weight: 600;
}

.menu-button {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.menu-button:hover {
  color: #ddd;
}

/* Style the Logout button to match the nav links */
.navLink button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  /* margin-top: 50%; */
  transition: color 0.3s ease, background-color 0.3s ease;
  /* padding: 0.5rem 1rem; */
  font-family: inherit; /* Ensure the font matches other nav links */
}

.navLink button:hover {
  color: #ff5722; /* Same hover effect as nav links */
  background-color: rgba(0, 0, 0, 0.05); /* Optional: Add a subtle background on hover */
  border-radius: 5px; /* Optional: Add rounded corners */
}

.navLink button:focus {
  outline: none; /* Remove default button focus outline */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for focus */
}

.profile-icon {
  position: relative;

  width: 40px;  /* Define a fixed width */
  height: 40px; /* Define a fixed height */
}

.menu-button {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  height: 100%;
}

.menu-button:hover {
  color: #ff5722;
}
.dropdown {
  position: absolute;

  /* top: 45px;  Adjust the distance from the button */
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
  width:150px;
  left: -100px;

  /* width: 180px;  Fixed width */
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown.open {
  opacity: 1;
  transform: translateY(0);
  display: block;
}
/* Smooth transition effect for dropdown appearance */
.dropdown {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown.open {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-item-navbar {
  /* padding: 12px 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  padding: 12px;
}

.dropdown-item-navbar:hover
{
  color:#ff5722;
  background-color: #ddd;
}
.profile-icon,
.menu-button {
  margin: 0;
  padding: 0;
}



/* Default Navbar Style */
.navbar {
  font-size: var(--text-font-size);
  /* min-width: 320px; */

  background-color: var(--color-bg-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  z-index: 9;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-bg-default);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 32%; */
}

.navbar-logo {
  width: 35px;
  border-radius: 5px;
  height: auto;
  margin: auto;
}

.header-right {
  /* width: 68%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -10px;
}

.navLinks {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.navLinks li {
  margin: 0 1rem;
}

.navLink {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #ff5722;
}

.navLink.profile, .navLink.logout
{
  display: none;
}

.no-auth-login {
  
  /* width: 28%; */
  width: 5%;
  margin-right: 20px;
  font-weight: normal;
  font-size: medium;
}

.logo-container-div {
  display: flex;
  gap: 10px;
}

.logo-text-h2-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-size: large;
  text-align: center;
  gap: 5px;
  font-weight: bold;
  color: #0C3934;
}

.logo-text-h2-div p {
  margin: 0;
  font-size: inherit;
}

/* Media Queries for Mobile */
@media (max-width: 1000px) {

 
  
  .navLinks {
    visibility: hidden; /* Initially hidden, no need to remove from the flow */
    opacity: 0; /* Initially invisible */
    transform: translateY(-20px); /* Initially positioned off-screen */
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    transition: visibility 0s 0.3s, opacity 0.3s ease, transform 0.3s ease; /* Smooth fade and slide effect */
    overflow-y: scroll;
    /* max-height: 180vh; */
  }

  .navLinks.open {
    visibility: visible; /* Make the element visible when open */
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Slide in */
    transition: visibility 0s, opacity 0.3s ease, transform 0.3s ease; /* Transition for the visible state */
  }

  .navLink {
    width: 100%;
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0; /* Add line between links */
  }

  .navLink:last-child {
    border-bottom: none; /* Remove border from the last link */
  }

  .navLink a {
    color: #333;
    text-decoration: none;
    display: block;
  }

  .navLink a:hover {
    color: #ff5722;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 20;
    margin-right: 2rem;
  }

  .navbar-menu span {
    height: 0.25rem;
    width: 100%;
    background-color: #333;
    border-radius: 0.2rem;
    transition: all 0.3s ease-in-out;
  }

  .navbar-menu.open span:nth-child(1) {
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    transform-origin: center;
  }

  .navbar-menu.open span:nth-child(2) {
    opacity: 0;
  }

  .navbar-menu.open span:nth-child(3) {
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    transform-origin: center;
  }

  .profile-icon {
    display: none;
  }

  .navLink.profile, .navLink.logout {
    display: block;
  }
  
  .no-auth-login {
    font-size: large;
    margin-right: 10px;
    width: 30%;
  }

  .logo-text-h2-div {
    font-size: medium;
  }
}

@media (min-width: 378px) and (max-width: 1000px) {
  .no-auth-login {
    font-size: large;
    width: 18%;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .no-auth-login {
    font-size: large;
    margin-right: 10px;
    width: 30%;
  }
}