/* General page styling */
.referrals-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  text-align: center;
}

/* Header styling */
.referrals-container h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: 700;
}

/* Paragraph styling */
.referrals-container p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #7f8c8d;
  margin-bottom: 30px;
}

/* Table container */
.referral-table {
  overflow-x: auto;
  margin-bottom: 30px;
}

/* Table styling */
.referral-table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Table header */
.referral-table thead {
  background-color: #2980b9;
  /* color: #fff; */
}

.referral-table th, 
.referral-table td {
  padding: 15px;
  text-align: center;
}

.referral-table th {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
}

/* Table body rows */
.referral-table tr:nth-child(even) {
  background-color: #ecf0f1;
}
.referral-table td {
  font-size: 1rem;
  color: #2c3e50;
}

/* Referral link section styling */
.referral-link-section {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.referral-link-section p {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #34495e;
}

/* Input field styling */
.referral-link-section input {
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  text-align: center;
  color: #34495e;
  margin-bottom: 10px;
  transition: border 0.3s;
}

.referral-link-section input:focus {
  border-color: #2980b9;
  outline: none;
}


/* Copy link button styling */
.referral-link-section button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2980b9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.referral-link-section button:hover {
  background-color: #1e6d91;
}

/* Responsive design */
@media screen and (max-width: 1000px) {
  .referrals-container {
      padding: 10px;
  }

  .referrals-container h1 {
      font-size: 2rem;
  }

  .referral-table th,
  .referral-table td {
      padding: 10px;
      color: #333;
  }

  .referral-link-section input {
      width: 100%;
  }
}

.referral-calculator-div-bg {
  height: 100vh;
  background: #00000079;
  width: 200vh;
}

.calculator-section {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.calculator-section h2 {
  font-size: 1.8rem;
  color: #2980b9;
  margin-bottom: 20px;
}

.calculator-section input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.1rem;
}

.calculator-section button {
  background-color: #2980b9;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 100%;
}

.calculator-section button:hover {
  background-color: #1e6d91;
}

.calculator-section #result {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #2c3e50;
}
.show-calculator-btn {
  background-color: #ff5733;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.calculator-popup {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.referral-calculator-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}

.cross-close {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #555;
  transition: all 0.3s ease;
}

.cross-close:hover {
  color: #000;
  transform: scale(1.1);
}

/* From Uiverse.io by arieshiphop */ 
.referral-button {
  font-size: 17px;
  padding: 0.5em 2em;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
  background: dodgerblue;
  color: white;
  border-radius: 4px;
  margin-bottom: 20px;
 }
 
 .referral-button:hover {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(30,144,255,1) 0%, rgba(0,212,255,1) 100%);
 }
 
 .referral-button:active {
  transform: translate(0em, 0.2em);
 }
/* Withdrawal Button */
.withdrawal-btn {
  background-color: #4CAF50;
  color: white;
  padding: 15px 25px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.withdrawal-btn:hover {
  background-color: #45a049;
}

.withdrawal-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Modal Form Container (hidden by default) */
.withdrawal-form-container {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
  justify-content: center;
  align-items: center;
}

/* Modal Form */
.withdrawal-form {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
  animation: slide-down 0.5s ease-in-out;
  margin-bottom: 10px;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 24px;
  cursor: pointer;
}

.close-btn:hover {
  color: #000;
}

/* Form Labels and Inputs */
.withdrawal-form label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.withdrawal-form input[type="number"],
.withdrawal-form input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Submit Button */
.submit-btn {
  background-color: #2196F3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0b7dda;
}

.referral-earnings {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  margin: 0 auto; /* Center the section */
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.referral-earnings h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.referral-earnings p {
  font-size: 20px;
  color: #2ecc71;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Withdrawal Button */
.withdrawal-btn {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-heading tr th{
  color: white;
}

.overlay-ref {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content-ref {
  /* background: #fff; */
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  text-align: center;
}

@media (max-width: 768px) {
  .calculator-popup{
    width: 100%;
  }
}

.paid-referred-users-div{
  margin-top: -25px;
}