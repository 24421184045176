/* Container */
.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;
  /* background: linear-gradient(135deg, #f0f4f8, #d9e2ec); */
  padding: 20px;
}

/* Main Content */
.home-page-main-content {
  max-width: 900px;
  width: 100%;
  padding: 50px; /* Extra padding for a classic look */
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

/* Dashboard */
.home-page-dashboard {
  width: 100%;
  max-width: 300px;
  background: #0f3460;
  color: #fff;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.1);
  animation: slideInLeft 0.5s;
}

/* Headings */
.home-page-main-content h1 {
  font-size: 1.5em; /* Slightly larger */
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}

.home-page-main-content p {
  font-size: 1.25em;
  color: #333;
  line-height: 1.8; /* Increased for classic readability */
  text-align: center;
  margin: 20px 0;
}

.home-page-main-content h2 {
  font-size: 1.0em;
}

/* Default font size */
.homepage-portfolio-details {
  font-size: 1em; /* Default font size */
  line-height: 1.5;
}

/* Responsive font size for small screens */
@media (max-width: 600px) {
  .homepage-portfolio-details {
    font-size: 0.9em; /* Adjust font size for smaller screens */
    line-height: 1.4; /* Adjust line height */
  }
}

/* Earnings */
.home-page-earnings {
  background: #1a1a2e;
  padding: 25px; /* Padding for emphasis */
  border-radius: 15px;
  margin: 20px 0;
  text-align: center;
  transition: transform 0.3s;
  color: #fff;
}

.home-page-earnings:hover {
  transform: scale(1.05); /* Gentle scale for interactivity */
}

/* Feature Cards */
.home-page-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 25px;
  margin-top: 40px;
}

.home-page-feature {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 300px;
}

.home-page-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Feature Headings */
.home-page-feature h2 {
  color: #2575fc;
  text-align: center;
  font-size: 1.5em; /* Classic-sized heading */
}

/* Feature Text */
.home-page-feature p {
  font-size: 1.15em;
  color: #555;
  text-align: center;
}

/* User Info */
.user-info-container {
  text-align: center;
  padding: 25px;
  border-radius: 15px;
  background-image: linear-gradient(to right, #afb9c0, #5f656b);
  color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 1.15em; /* Larger font */
}

/* Plan-Specific User Info Styling */
.user-info-container.Gold {
  background-image: linear-gradient(to right, #f9d423, #ff4e50);
  color: #fff;
  box-shadow: 0 4px 20px rgba(255, 215, 0, 0.5);
}

.user-info-container.Silver {
  background-image: linear-gradient(to right, #afb9c0, #5f656b);
  color: #fff;
  box-shadow: 0 4px 20px rgba(192, 192, 192, 0.5);
}

/* Credential Instructions */
.credential-instructions {
  background-color: #f7f9fc;
  border: 1px solid #dcdfe6;
  border-radius: 15px;
  padding: 2px;
  margin: 20px 0;
  color: #333;
  font-size: 1.05em; /* Larger text */
}

.credential-instructions a {
  /* color: #4a90e2; */
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
}

.credential-instructions:hover {
  background-color: #ecedee;
}

/* Investment Summary */
.investment-summary {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  font-family: "Arial", sans-serif;
  color: #333;
  text-align: center;
}

.investment-summary p {
  font-size: 1.25em;
  line-height: 1.8;
}

/* Highlight Text */
.highlight {
  font-weight: bold;
  color: #2575fc;
}

.profit {
  color: #28a745;
}

.loss {
  color: #dc3545;
}

.credential-instructions {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.download-instructions {
  margin: 10px 0;
  display: flex;
  gap: 15px;
  justify-content: flex-start;
}

.download-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}
.download-button:hover {
  background-color: #0056b3;
}

.login-failed-message {
  color: red;
  font-weight: bold;
}

.home-page-user-guidelines {
  width: 100%;
  overflow: fixed;
}

/* feedback  */
.feedback-btn-div {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

.feedback-btn {
  display: flex;
  align-items: center;
  background-color: #00c3b9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #009e94;
}

.feedback-inside-btn-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #00c3b9;
  width: 30px;
  height: 30px;
}

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 450px) {
  .home-page-main-content h1 {
    font-size: 0.9em;
  }

  .home-page-main-content h2 {
    font-size: 0.9em;
  }
}

/* Responsive */
@media (max-width: 1000px) {
  .home-page-main-content {
    padding: 25px;
  }

  .home-page-feature {
    max-width: 100%;
  }
}
